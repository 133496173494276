<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    scrollable
    @keydown.esc="onClose"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">
      <slot name="customButton" :click="onNewClicked" :v-on="on" :isEdit="isEdit"></slot>
    </template>

    <details-card scrollable>
      <v-card-title>
        <prosk-title class="title">
          {{ isEdit ? $t("editCoupon") : $t("createCoupon") }}
        </prosk-title>
      </v-card-title>
      <v-card-text>
        <v-row align="start">
          <v-col cols="6">
            <custom-text-field
              :label="$t('couponCode')"
              v-model="code"
              :rules="couponRules"
            ></custom-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :label="$t('couponDiscountType')"
              :items="discountType"
              :v-model="discount_type"
              item-text="name"
              item-value="discount_type"
              @change="value => discount_type = value"
              >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuStartDate"
              v-model="menuStartDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <custom-text-field
                  v-model="startDateFormatted"
                  :label="$t('startDate')"
                  readonly
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  :click="on.click"
                ></custom-text-field>
              </template>
              <v-date-picker
                v-model="start_date"
                no-title
                @input="menuStartDate = false"
                :locale="$i18n.locale"
                show-adjacent-months
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuEndDate"
              v-model="menuEndDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <custom-text-field
                  v-model="endDateFormatted"
                  :label="$t('endDate')"
                  readonly
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  :click="on.click"
                ></custom-text-field>
              </template>
              <v-date-picker
                v-model="end_date"
                no-title
                @input="menuEndDate = false"
                :locale="$i18n.locale"
                show-adjacent-months
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <custom-text-field
              textarea
              :label="$t('couponDescription')"
              v-model="description"
              rows="2"
            ></custom-text-field>
          </v-col>
          <v-col cols="6">
            <custom-text-field
              :label="$t('couponAmount')"
              v-model="amount"
              :rules="couponAmountRules"
            ></custom-text-field>
          </v-col>
          <v-col cols="6">
            <custom-text-field
              :label="$t('couponMinOrderValue')"
              v-model="minimum_order_value"
              :rules="couponMinValueRules"
            ></custom-text-field>
          </v-col>
          <v-col cols="6">
            <custom-text-field
              :label="$t('couponUsageLimit')"
              v-model="usage_limit"
              :rules="couponLimitRules"
            ></custom-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :label="$t('couponEnabled')"
              :items="couponEnabled"
              :v-model="enabled"
              item-text="name"
              item-value="enabled"
              @change="value => enabled = value"
              >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button :action="onClose" :message="$t('cancel')" outlined />
        <general-button :action="save" :loading="loading" :message="$t('save')" primary />
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
// import { mapActions } from 'vuex';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';
import DetailsCard from '@/components/socialvue/cards/DetailsCard.vue';
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';

export default {
  name: 'CouponDialog',
  components: { CustomTextField, DetailsCard, GeneralButton, ProskTitle },
  props: ['showAdd', 'onClose', 'item', 'newItem', 'isEdit'],
  data () {
    return {
      loading: false,
      code: null,
      discount_type: null,
      description: null,
      amount: null,
      minimum_order_value: null,
      usage_limit: null,
      enabled: null,
      start_date: null,
      end_date: null,
      menuStartDate: false,
      menuEndDate: false,
      startDateFormatted: null,
      endDateFormatted: null,
      discountType: [
        {
          id: 0,
          discount_type: 'percentage',
          name: this.$t('couponPercentage')
        },
        {
          id: 1,
          discount_type: 'fixed_amount',
          name: this.$t('couponFixMount')
        }
      ],
      couponEnabled: [
        {
          id: 0,
          enabled: 1,
          name: this.$t('couponActive')
        },
        {
          id: 1,
          enabled: 0,
          name: this.$t('couponDisabled')
        }
      ],
      couponRules: [
        v => !!v || this.$t('fieldCouponRequired')
      ],
      couponTypeRules: [
        v => !!v || this.$t('fieldCouponTypeRequired')
      ],
      couponAmountRules: [
        v => !!v || this.$t('fieldCouponAmountRequired')
      ],
      couponMinValueRules: [
        v => !!v || this.$t('fieldCouponMinValueRequired')
      ],
      couponLimitRules: [
        v => !!v || this.$t('fieldCouponLimitRequired')
      ]
    };
  },
  watch: {
    'start_date': function (val) {
      this.startDateFormatted = this.formatDate(val);
    },
    'end_date': function (val) {
      this.endDateFormatted = this.formatDate(val);
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return this.$i18n.locale.toLowerCase().includes('en') ? `${month}/${day}/${year}` : `${day}/${month}/${year}`;
    },
    onNewClicked () {
      this.reset();
      this.newItem();
    },
    reset () {
      this.code = null;
      this.discount_type = null;
      this.description = null;
      this.amount = null;
      this.minimum_order_value = null;
      this.usage_limit = null;
      this.enabled = null;
      this.start_date = null;
      this.end_date = null;
    },
    save () {
      this.loading = true;
      const data = {
        code: this.code,
        discount_type: this.discount_type,
        description: this.description,
        amount: this.amount,
        minimum_order_value: this.minimum_order_value,
        usage_limit: this.usage_limit,
        enabled: this.enabled,
        start_date: this.start_date,
        end_date: this.end_date
      };

      const url = this.isEdit
        ? `${process.env.VUE_APP_BASE_URL}/api/promotional-codes/${this.item.id}`
        : `${process.env.VUE_APP_BASE_URL}/api/promotional-codes`;

      const method = this.isEdit ? 'put' : 'post';

      axios[method](url, data)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    }
  }
};
</script>
<style scoped>
  h3.title {
    margin: 0;
    padding: 0 9px;
  }

  .relative {
    position: relative;
    width: 100%;
  }

  .absolute {
    position: absolute;
    top: 20px;
    right: 12px;
  }
</style>
