<template>
  <v-main class="container-prosker">
    <v-data-table
      :options.sync="pagination"
      :server-items-length="pagination.totalItems"
      :loading="loadingTable"
      :search="search"
      :headers="getHeaders"
      :footer-props="{ itemsPerPageText: $t('rowsPerPage') }"
      :items="couponsData"
      item-key="id"
      class="elevation-1 mt-5"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('adminCoupons') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-row class="w-100">
            <v-col cols="4" sm="2">
              <v-select
                class="ml-2"
                hide-details
                color="#59D79D"
                v-model="selectedOption"
                :items="getCouponFilter"
                :label="$t('couponFilter')"
                :item-text="'name'"
                :item-value="'value'"
                @change="list"
              />
            </v-col>
            <v-col cols="6" sm="8">
              <v-text-field
                :label="$t('search')"
                append-icon="search"
                hide-details
                single-line
                v-model="search"
                color="#59D79D"
              />
            </v-col>
            <v-col cols="2" class="text-end">
              <v-btn color="#59D79D" dark @click="showCouponDialog">
                {{ $vuetify.breakpoint.mobile ? '+' : $t('createCoupon') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:no-data>
        <v-alert :value="true" color="error" icon="warning" class="white--text">
          <span class="white--text">{{ $t('noData') }}</span>
        </v-alert>
      </template>

      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          <span class="white--text">
            {{ $t('yourSearchFor') }} "{{ search }}" {{ $t('foundNoResults') }}
          </span>
        </v-alert>
      </template>
      <template v-slot:item.code="{ item }">
        <span class="caption">{{ item.code }}</span>
      </template>
      <template v-slot:item.description="{ item }">
        <span class="caption">{{ item.description }}</span>
      </template>
      <template v-slot:item.discount_type="{ item }">
        <span class="caption">{{ item.discount_type }}</span>
      </template>
      <template v-slot:item.start_date="{ item }">
        <span class="caption">{{ item.start_date }}</span>
      </template>
      <template v-slot:item.end_date="{ item }">
        <span class="caption">{{ item.end_date }}</span>
      </template>
      <template v-slot:item.amount="{ item }">
        <span class="caption">{{ item.amount }}</span>
      </template>
      <template v-slot:item.minimum_order_value="{ item }">
        <span class="caption">{{ item.minimum_order_value }}</span>
      </template>
      <template v-slot:item.usage_limit="{ item }">
        <span class="caption">{{ item.usage_limit }}</span>
      </template>
      <template v-slot:item.enabled="{ item }">
          <v-switch
            v-model="item.enabled"
            color="#59D79D"
            class="mt-0"
            :disabled="true"
          />
    </template>
      <template v-slot:item.action="{ item }">
        <tooltip-button
          icon="mdi-pencil"
          :action="editItem"
          :item="item"
          color="secondary"
          message="edit"
        />
      </template>
    </v-data-table>
    <coupon-dialog :on-close="onAddClose" :show-add.sync="showAdd" />
  </v-main>
</template>

<script>
import axios from 'axios';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
import CouponDialog from '@/components/socialvue/dialogs/CouponDialog';

export default {
  name: 'CouponInformation',
  components: {
    CouponDialog,
    TooltipButton
  },
  data () {
    return {
      couponsData: [],
      pagination: {
        totalItems: 0,
        per_page: 10,
        page: 1
      },
      loadingTable: false,
      search: '',
      selectedOption: null,
      showAdd: false
    };
  },
  created () {
    this.selectedOption = this.getCouponFilter[0].enabled;
    this.selectedOption = this.getCouponFilter[0].disabled;
    this.callback();
  },
  computed: {
    getHeaders () {
      return [
        { text: this.$t('couponCode'), value: 'code', width: '15%' },
        { text: this.$t('couponDescription'), value: 'description', width: '15%' },
        { text: this.$t('couponDiscountType'), value: 'discount_type' },
        { text: this.$t('startDate'), value: 'start_date', width: '15%' },
        { text: this.$t('endDate'), value: 'end_date', width: '15%' },
        { text: this.$t('couponAmount'), value: 'amount' },
        { text: this.$t('couponMinOrderValue'), value: 'minimum_order_value' },
        { text: this.$t('couponUsageLimit'), value: 'usage_limit' },
        { text: this.$t('couponEnabled'), value: 'enabled' },
        { text: this.$t('couponActions'), value: 'action', sortable: false }
      ];
    },
    getCouponFilter () {
      return [
        { name: this.$t('all'), value: null },
        { name: this.$t('enabled'), value: 1 },
        { name: this.$t('disabled'), value: 0 }
      ];
    }
  },
  methods: {
    editItem (item) {
      this.$router.push({
        name: 'social.coupons.edit-coupon',
        params: { id: item?.code }
      });
    },
    callBack () {
        this.list();
    },
    resetPagination () {
        if (!this.isDirty) {
            this.pagination = {
                totalItems: 0,
                per_page: 10,
                page: 1,
                sortBy: ['id']
            };
        }
    },
    onAddClose () {
        this.showAdd = false;
    },
    showCouponDialog () {
      console.log('Clic en el botón, abriendo modal');
      this.showAdd = true;
    },
    list () {
        this.isDirty = true;
        this.servicesData = [];
        this.loadingTable = true;

        const filterOption = (this.selectedOption !== null && this.selectedOption !== undefined &&
                this.selectedOption !== false)
                    ? `filters=enabled=${this.selectedOption}`
                    : `''`;
        console.log(this.selectedOption);

        const perPage = this.pagination.itemsPerPage
            ? this.pagination.itemsPerPage === -1
                ? this.pagination.totalItems
                    : this.pagination.itemsPerPage : 10;

        axios.get(`${process.env.VUE_APP_BASE_URL}/api/promotional-codes?${filterOption}${this.search.trim().length !== 0 ? `
            &search=${this.search}` : ''}&page=${this.pagination.page}&per_page=${perPage}`)
                .then((response) => {
                    this.isDirty = false;
                    this.pagination.totalItems = response.data.total;
                    this.couponsData = response.data.data;
                    this.loadingTable = false;
                }).catch((error) => {
                    this.isDirty = false;
                    this.loadingTable = false;
                    this.showError(error);
                });
    }
  },
  watch: {
        'search': {
            handler () {
                this.resetPaginationForSearch(this);
            },
            deep: true
        },
        'pagination': {
            handler () {
                if (!this.isDirty) {
                    this.list();
                }
            },
            deep: true
        }
    }
};
</script>
